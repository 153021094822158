import React from "react";
import "./document.css";
const Privacy = () => {
  return (
    <div className="policy-wrapper">
      <h1>Privacy Policy</h1>
      <p>
        Saisuman (“us”, “we”, or “our”) operates the codewithkrish.com website
        (the “Service”). <br /> <br /> This page informs you of our policies
        regarding the collection, use and disclosure of Personal Information
        when you use our Service. <br /> <br /> We will not use or share your
        information with anyone except as described in this Privacy Policy.{" "}
        <br /> <br /> We use your Personal Information for providing and
        improving the Service. By using the Service, you agree to the collection
        and use of information in accordance with this policy. Unless otherwise
        defined in this Privacy Policy, terms used in this Privacy Policy have
        the same meanings as in our Terms and Conditions, accessible at
        codewithkrish.com <br /> <br />
        Information Collection And Use <br /> <br /> While using our Service, we
        may ask you to provide us with certain personally identifiable
        information that can be used to contact or identify you. Personally
        identifiable information may include, but is not limited to, your email
        address, name, phone number, postal address, other information
        (“Personal Information”). <br /> <br />
        We collect this information for the purpose of providing the Service,
        identifying and communicating with you, responding to your
        requests/inquiries, servicing your purchase orders, and improving our
        services. <br /> <br /> Log Data <br /> <br />
        We may also collect information that your browser sends whenever you
        visit our Service (“Log Data”). This Log Data may include information
        such as your computer’s Internet Protocol (“IP”) address, browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages and other statistics.{" "}
        <br /> <br /> In addition, we may use third party services such as
        Google Analytics that collect, monitor and analyze this type of
        information in order to increase our Service’s functionality. These
        third party service providers have their own privacy policies addressing
        how they use such information. <br /> <br /> Cookies <br /> <br />
        Cookies are files with a small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and transferred to your device. We use cookies to collect
        information in order to improve our services for you. <br /> <br /> You
        can instruct your browser to refuse all cookies or to indicate when a
        cookie is being sent. The Help feature on most browsers provide
        information on how to accept cookies, disable cookies or to notify you
        when receiving a new cookie.
        <br />
        If you do not accept cookies, you may not be able to use some features
        of our Service and we recommend that you leave them turned on. <br />{" "}
        <br />
        DoubleClick Cookie <br /> <br /> Google, as a third party vendor, uses
        cookies to serve ads on our Service. Google’s use of the DoubleClick
        cookie enables it and its partners to serve ads to our users based on
        their visit to our Service or other web sites on the Internet.
        <br />
        You may opt out of the use of the DoubleClick Cookie for interest-based
        advertising by visiting the Google Ads Settings web page. <br /> <br />{" "}
        Behavioral Remarketing <br /> <br /> Saisuman uses remarketing services
        to advertise on third party web sites to you after you visited our
        Service. <br /> <br /> We, and our third party vendors, use cookies to
        inform, optimize and serve ads based on your past visits to our Service.{" "}
        <br /> <br />
        Google AdWords remarketing service is provided by Google Inc.You can
        opt-out of Google Analytics for Display Advertising and customize the
        Google Display Network ads by visiting the Google Ads Settings
        page.Google also recommends installing the Google Analytics Opt-out
        Browser Add-on for your web browser. Google Analytics Opt-out Browser
        Add-on provides visitors with the ability to prevent their data from
        being collected and used by Google Analytics.For more information on the
        privacy practices of Google, please visit the Google Privacy & Terms web
        page. <br /> <br /> Do Not Track Disclosure <br /> <br />
        We support Do Not Track (“DNT”). Do Not Track is a preference you can
        set in your web browser to inform websites that you do not want to be
        tracked. You can enable or disable Do Not Track by visiting the
        Preferences or Settings page of your web browser. <br /> <br />
        Service Providers <br /> <br /> We may employ third party companies and
        individuals to facilitate our Service, to provide the Service on our
        behalf, to perform Service-related services and/or to assist us in
        analyzing how our Service is used. These third parties have access to
        your Personal Information only to perform specific tasks on our behalf
        and are obligated not to disclose or use your information for any other
        purpose. <br /> <br />
        Communications <br /> <br /> We may use your Personal Information to
        contact you with newsletters, marketing or promotional materials and
        other information that may be of interest to you. You may opt out of
        receiving any, or all, of these communications from us by following the
        unsubscribe link or instructions provided in any email we send.
        <br /> <br /> Compliance With Laws <br /> <br /> We will disclose your
        Personal Information where required to do so by law or subpoena or if we
        believe that such action is necessary to comply with the law and the
        reasonable requests of law enforcement or to protect the security or
        integrity of our Service.
        <br /> <br />
        Business Transaction
        <br /> <br /> If Saisuman is involved in a merger, acquisition or asset
        sale, your Personal Information may be transferred as a business asset.
        In such cases, we will provide notice before your Personal Information
        is transferred and/or becomes subject to a different Privacy Policy.
        <br /> <br /> Security
        <br /> <br /> The security of your Personal Information is important to
        us, and we strive to implement and maintain reasonable, commercially
        acceptable security procedures and practices appropriate to the nature
        of the information we store, in order to protect it from unauthorized
        access, destruction, use, modification, or disclosure. However, please
        be aware that no method of transmission over the internet, or method of
        electronic storage is 100% secure and we are unable to guarantee the
        absolute security of the Personal Information we have collected from
        you. <br /> <br />
        International Transfer
        <br /> <br /> Your information, including Personal Information, may be
        transferred to — and maintained on — computers located outside of your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from your jurisdiction. If
        you are located outside India and choose to provide information to us,
        please note that we transfer the information, including Personal
        Information, to India and process it there. Your consent to this Privacy
        Policy followed by your submission of such information represents your
        agreement to that transfer.
        <br /> <br /> Links To Other Sites <br /> <br />
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party’s site. We strongly advise you to review the Privacy Policy
        of every site you visit.
        <br /> <br /> We have no control over, and assume no responsibility for
        the content, privacy policies or practices of any third party sites or
        services.
        <br /> <br /> Children’s Privacy
        <br /> <br /> Only persons aged 13 or older have permission to access
        our Service. Our Service does not address anyone under the age of 13
        (“Children”).
        <br /> <br /> We do not knowingly collect personally identifiable
        information from children under 13. If you are a parent or guardian and
        you learn that your Children have provided us with Personal Information,
        please contact us. If we become aware that we have collected Personal
        Information from a children under age 13 without verification of
        parental consent, we take steps to remove that information from our
        servers. <br /> <br />
        Changes To This Privacy Policy
        <br /> <br /> This Privacy Policy is effective as of November 01, 2018
        and will remain in effect except with respect to any changes in its
        provisions in the future, which will be in effect immediately after
        being posted on this page. <br /> <br />
        We reserve the right to update or change our Privacy Policy at any time
        and you should check this Privacy Policy periodically. Your continued
        use of the Service after we post any modifications to the Privacy Policy
        on this page will constitute your acknowledgment of the modifications
        and your consent to abide and be bound by the modified Privacy Policy.
        <br /> <br /> If we make any material changes to this Privacy Policy, we
        will notify you either through the email address you have provided us,
        or by placing a prominent notice on our website. <br /> <br />
        Contact Us
        <br /> <br /> If you have any questions about this Privacy Policy,
        please contact us.
      </p>
    </div>
  );
};

export default Privacy;
