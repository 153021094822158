import React from "react";
import "./document.css";

const Shipping = () => {
  return <div className="policy-wrapper">
    <h1>Shipping & Delivery Policy</h1>
    <p>Shipping is not applicable for business.</p>
  </div>;
};

export default Shipping;
