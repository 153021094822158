import React, { useEffect, useState } from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import Footer from "./components/footer/Footer";
import Portfolio from "./components/portfolio/Portfolio";
import Services from "./components/services/Services";
import Testimonials from "./components/testimonials/Testimonials";
import Aos from "aos";
import Welcome from "./components/welcome/Welcome";

const Home = () => {
  const [view, setView] = useState(true);
  const [active, setActive] = useState("#");

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setView(false);
    }, 3000);
  }, []);
  return (
    <>
      {view ? (
        <Welcome />
      ) : (
        <>
          <Header active={active} setActive={setActive} />
          <Nav active={active} setActive={setActive} />
          <About />
          <Experience />
          {/* <Services /> */}
          <Portfolio />
          {/* <Testimonials /> */}
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
